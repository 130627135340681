import React from "react";
import { withRouter } from "react-router-dom";

// UI
import Panel, { PanelTitle } from "../ui/Panel";

class OauthHandler extends React.Component {
  
  constructor() {
    super();

    this.state = {
      error: ""
    };
  }
  
  componentDidMount() {
    
    const params = new URLSearchParams(window.location.search);

    // Successful authentication
    if(
      params.get("access_token") &&
      params.get("refresh_token") &&
      params.get("timezone")
    ) {

      localStorage.setItem("access_token", params.get("access_token"));
      localStorage.setItem("refresh_token", params.get("refresh_token"));
      localStorage.setItem("timezone", params.get("timezone"));

      if(params.get("new_account")) {
        localStorage.setItem("timezone_preference_missing", true);
      }

      // Provide the chat widget with some user identifiers
      if(
        params.get("id") &&
        params.get("email") &&
        params.get("first_name") &&
        params.get("last_name")
      ) {
        try {
          window.tidioChatApi.setVisitorData({
            distinct_id: params.get("id"),
            email: params.get("email"),
            name: params.get("first_name") + " " + params.get("last_name")
          });
        } catch {
          console.error(`Couldn't set Tidio visitor data.`);
        }
      }
      
      this.props.history.push("/");
    }

    // Failed authentication
    if(params.get("error")) {

      if(
        params.get("error") === "trial_ended" &&
        params.get("user_id") &&
        params.get("trial_key")
      ) {
        this.props.history.push(`/subscribe/${params.get("user_id")}/${params.get("trial_key")}`);
      }
      
      this.setState({ error: params.get("error") });
    } else {
      this.props.history.push("/signup");
    }
  }

  errorContent() {
    switch(this.state.error) {
      case "acct_inactive":
        return (
          <div className="centered">
            <p>This account has been deactivated. Please contact <a href="mailto:support@screenlapse.com">support@screenlapse.com</a> for help.</p>
          </div>
        );
      case "signup_failed":
        return (
          <div className="centered">
            <p>Account creation failed. Please try again or contact <a href="mailto:support@screenlapse.com">support@screenlapse.com</a> for help.</p>
          </div>
        );
      default:
        return (
          <div className="centered">
            <p>Sorry! We've run into an issue. Please try again or contact <a href="mailto:support@screenlapse.com">support@screenlapse.com</a> for help.</p>
          </div>
        );
    }
  }
  
  render() {
    
    return (
      <div>
        
        <Panel spaced hasTitle>

          <PanelTitle>Authentication Failed</PanelTitle>
          {this.errorContent()}

        </Panel>
        
      </div>
    );
  }
}

export default withRouter(OauthHandler);